<template>
    <div class="container gradient_box">
        <div class="header">
            <img src="../../assets/images/moli/logo.png" alt="Molly Tea Logo" class="logo">
            <h1 class="title">Win a Jasmine Tea Latte</h1>
            <p class="subtitle">Select premium ingredients to craft your perfect Jasmine Tea Latte!</p>
        </div>
        <div class="middle" :class="{reward: !all_done, received: all_done}">
            <div class="cup"></div>
            <div class="main">
                <div class="reward-card" v-if="!all_done">
                    <div class="selection-list">
                        <div class="selection-item">Precious Jasmine Tea</div>
                        <div class="selection-item">Fresh Milk</div>
                        <div class="selection-item">
                            No Topping
                            <span style="font-weight: bolder; padding-left: 5px;">Low calorie</span>
                        </div>
                    </div>
                    <h2 class="congrats-text">Congratulations</h2>
                    <p class="login-text">Claim your rewards</p>
                    <div class="input-group">
                        <input type="tel" v-model="phone" class="input-field" placeholder="Phone" maxlength="10">
                        <div class="error-message" id="phone-error">Please enter the correct phone number</div>
                        <div class="verification-group">
                            <input type="number" v-model="code" class="verification-input" placeholder="Verification">
                            <button class="send-button" :disabled="!can_send || sending" @click="doSendVerify">
                                {{ sending ? sending_time + 's' : 'Send' }}
                            </button>
                        </div>
                        <div class="error-message" id="verification-error">The verification has expired</div>
                        <input type="text" v-model="email" class="input-field" placeholder="Email">
                    </div>
                    <button class="login-button" :disabled="!can_login" @click="doLogin">Sign up to claim</button>
                </div>
                <div class="reward-card" v-if="all_done">
                    <h2 class="congrats-text" v-if="after_submit">Your fresh milk tea is waiting for you. Stay stuned.</h2>
                    <h2 class="congrats-text" v-else>You’ve already earned a free cup of jasmine tea, stay stunned.</h2>
                    <span style="font-size: 13px; color: #1C9100; display: block; text-align: center;">You can only claim your prize at </span>
                    <span style="font-size: 13px; color: #1C9100; display: block; text-align: center;">Molly Tea’s Manhattan Chinatown location</span>
                    <span style="font-size: 13px; color: #1C9100; display: block; text-align: center;">63 Mott Street, Manhattan, NY 11220</span>
                    <button class="login-button" @click="doStartOver">Start Over</button>
                </div>
                <div class="minipass">Powered by minipass</div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
    import Swal from 'sweetalert2';

    export default {
        name: 'MoliGameDone',
        data() {
            return {
                now_step: window.localStorage.getItem('moli_step') || 1,
                phone: '',
                email: '',
                code: '',
                can_send: false,
                can_login: false,
                all_done: window.localStorage.getItem('moli_step') == 5 ? true : false,

                sending_time: 0,
                sending: false,
                timer: null,
                submitting: false,
                after_submit: false
            };
        },
        beforeUpdate() {},
        updated() {},
        beforeCreate() {},
        created() {
            this.init();
            if (this.now_step < 4) {
                this.$router.replace({
                    path: '/moli/' + this.now_step
                });
            }
        },
        watch: {
            phone() {
                if (this.phone.length == 10) {
                    this.can_send = true;
                }
            },
            code() {
                if (this.code.length > 0) {
                    this.can_login = true;
                }
            }
        },
        computed: {
            ...mapGetters({
                msg: 'app/get_app_msg'
            }),
            ...mapState({
            }),
        },
        methods: {
            ...mapActions({
                sendCode: 'draw/sendCode',
                verifyMoliCode: 'draw/verifyMoliCode',
                getMoliStatus: 'draw/getMoliStatus',
            }),
            ...mapMutations({
            }),
            async init() {
                let res = await this.getMoliStatus();

                if (res && res.code == 100005) {
                    this.$router.replace({
                        path: '/moli/over'
                    });
                }
            },
            async doSendVerify() {
                if (!this.phone || this.sending_time > 0) { return; }
                
                this.sending = true;
                this.code = '';

                await this.sendCode({
                    phone_num: '+1 ' + this.phone,
                    sid: 'moli_game'
                });

                this.sending_time = 60;
                this.timer = setInterval(() => {
                    this.sending_time = this.sending_time - 1;
                    if (this.sending_time == 0) {
                        clearInterval(this.timer);
                        this.sending = false;
                    }
                }, 1000);
            },
            async doLogin() {
                if (this.phone && this.code) {
                    if (this.submitting) {
                        return;
                    }

                    this.submitting = true;

                    let res = await this.verifyMoliCode({
                        sid: 'moli_game',
                        phone_num: '+1 ' + this.phone,
                        code: this.code,
                        email: this.email
                    });

                    if (res && res.code == 100000) {
                        this.all_done = true;
                        this.after_submit = true;
                        window.localStorage.setItem('moli_step', 5);
                    } else {
                        Swal.fire({
                            title: res.msg,
                            icon: 'error',
                            confirmButtonText: 'OK'
                        });
                    }

                    this.submitting = false;
                }
            },
            doStartOver() {
                window.localStorage.setItem('moli_step', 1);
                this.$router.replace({
                    path: '/moli/1'
                });
            }
        }
    };
</script>

<style scoped>
    @import '../../assets/style/moli/base.css';
    @import '../../assets/style/moli/index.css';
</style>